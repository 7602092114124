import request from 'api'
import API_GLOBAL from 'api/api'

class ClassAPI {
  // eslint-disable-next-line class-methods-use-this
  login = (data) =>
    request({
      url: 'v1/auth/login/verification',
      method: 'POST',
      data,
    })

  loginRequestEmail = (data) =>
    request({
      url: 'v1/auth/login',
      method: 'POST',
      data,
    })

  testToken = () =>
    request({
      url: 'v1/auth/test',
      method: 'GET',
    })

  refreshToken = () =>
    request({
      url: 'v1/auth/refresh-tokens',
      method: 'POST',
      data: { refreshToken: API_GLOBAL.refreshToken },
    })
}

const AUTH_API = new ClassAPI()
export default AUTH_API
