import request from 'api'

class ClassAPI {
  getOtts = (ottsId) =>
    request({
      url: `v1/otts/${ottsId}`,
      method: 'GET',
    })

  getSignedUrlPreview = ({ ottsId }) =>
    request({
      url: `v1/files/images/signedUrlPreview/${ottsId}`,
      method: 'GET',
    })

  download = (ottsId) =>
    request({
      url: `v1/files/signedUrlOriginalFile/${ottsId}`,
      method: 'GET',
    })
}

const OTTS_API = new ClassAPI()

export default OTTS_API
