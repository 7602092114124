import { connectRouter } from 'connected-react-router'
import storage from 'localforage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'

// Components
import graphTableReducer from '@components/Graphs/Table/reducer'
import modalsReducer from '@components/Modal/reducer'

// Modules
import authReducer from 'pages/Auth/redux/reducer'
import ottsReducer from 'pages/Otts/redux/reducer'
import paymentReducer from 'pages/Payment/redux/reducer'
import profileReducer from 'pages/Profile/redux/reducer'
// import specReducer from 'pages/Specs/redux/reducer'
import userReducer from 'pages/User/redux/reducer'

import { RESET_STORE } from '@components/StateManagement/constants'

const persistKey = `otts-05`

const rootPersistConfig = {
  transforms: [immutableTransform()],
  key: persistKey,
  whitelist: ['auth'],
  storage,
}

export const appReducer = (history) =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      router: connectRouter(history),
      auth: authReducer,
      otts: ottsReducer,
      payment: paymentReducer,
      profile: profileReducer,
      user: userReducer,
      // spec: specReducer,
      // Components
      modals: modalsReducer,
      graphTable: graphTableReducer,
    }),
  )

export const rootReducer = (reducer) => (state, action) => {
  if (action.type === RESET_STORE) {
    storage.removeItem(`persist:${persistKey}`).then(() => {
      // eslint-disable-next-line no-param-reassign
      state = undefined
    })
  }
  return reducer(state, action)
}
