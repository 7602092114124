import createAction from 'utils/createAction'

import {
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE,
  GET_AVALIABLE_PAYMENTS_METHODS_REQUEST,
  GET_AVALIABLE_PAYMENTS_METHODS_SUCCESS,
  CLEAR_PAYMENTS_DATA,
  GET_AVALIABLE_PAYMENTS_METHODS_FAILURE,
  CREATE_PAYMENT_TRANSACTIONS_REQUEST,
  CREATE_PAYMENT_TRANSACTIONS_SUCCESS,
  CREATE_PAYMENT_TRANSACTIONS_FAILURE,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
} from './constants'

export const getPlansRequest = createAction(GET_PLANS_REQUEST)
export const getPlansSuccess = createAction(GET_PLANS_SUCCESS)
export const getPlansFailure = createAction(GET_PLANS_FAILURE)

export const getAvaliablePaymentsMethodsRequest = createAction(
  GET_AVALIABLE_PAYMENTS_METHODS_REQUEST,
)
export const getAvaliablePaymentsMethodsSuccess = createAction(
  GET_AVALIABLE_PAYMENTS_METHODS_SUCCESS,
)
export const getAvaliablePaymentsMethodsFailure = createAction(
  GET_AVALIABLE_PAYMENTS_METHODS_FAILURE,
)

export const createPaymentTransactionsRequest = createAction(
  CREATE_PAYMENT_TRANSACTIONS_REQUEST,
)
export const createPaymentTransactionsSuccess = createAction(
  CREATE_PAYMENT_TRANSACTIONS_SUCCESS,
)
export const createPaymentTransactionsFailure = createAction(
  CREATE_PAYMENT_TRANSACTIONS_FAILURE,
)

export const clearPaymentsData = createAction(CLEAR_PAYMENTS_DATA)

export const subscribeRequest = createAction(SUBSCRIBE_REQUEST)
export const subscribeSuccess = createAction(SUBSCRIBE_SUCCESS)
export const subscribeFailure = createAction(SUBSCRIBE_FAILURE)
