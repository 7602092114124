import { fromJS } from 'immutable'

import {
  CLEAR_OTTS,
  GET_OTTS_FAILURE,
  GET_OTTS_REQUEST,
  GET_OTTS_SUCCESS,
} from './constants'

const initialState = fromJS({
  error: null,
  data: null,
  loading: false,
})

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_OTTS_REQUEST:
      return state.set('data', null).set('error', null).set('loading', true)
    case GET_OTTS_SUCCESS:
      const { data } = payload
      return state.set('data', data).set('error', null).set('loading', false)
    case GET_OTTS_FAILURE:
      const { error } = payload
      return state.set('data', null).set('error', error).set('loading', false)
    case CLEAR_OTTS:
      return initialState
    default:
      return state
  }
}

export default userReducer
