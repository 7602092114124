import React from 'react'
import Router from './Router'
import AuthChecker from 'pages/Auth/AuthChecker'

function App() {
  return (
    <AuthChecker>
      <Router />
    </AuthChecker>
  )
}

export default App
