export const downloadFile = async (downloadUrl, fileName) => {
  try {
    // Выполняем запрос по ссылке getSignedUrl
    const response = await fetch(downloadUrl)

    if (!response.ok) {
      throw new Error(`Ошибка загрузки: ${response.statusText}`)
    }

    // Получаем содержимое файла в виде Blob
    const blob = await response.blob()

    // Создаем временный URL для Blob
    const url = window.URL.createObjectURL(blob)

    // Создаем скрытый элемент <a> и эмулируем клик
    const link = document.createElement('a')
    link.href = url
    link.download = fileName // Указываем имя файла для загрузки
    document.body.appendChild(link)
    link.click()

    // Удаляем временный элемент и URL
    link.remove()
    window.URL.revokeObjectURL(url)

    return { success: true, message: 'Файл успешно загружен' }
  } catch (error) {
    return { success: false, message: error.message }
  }
}
