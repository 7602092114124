import request from 'api'
import API_GLOBAL from 'api/api'

class ClassAPI {
  // eslint-disable-next-line class-methods-use-this
  getUserInfo = () =>
    request({
      url: 'v1/user/info',
      method: 'GET',
    })
}

const USER_API = new ClassAPI()
export default USER_API
