import React from 'react'
import { Button, Result } from 'antd'

import Catch from './сatch'

const MyErrorBoundary = Catch(({ children, error }) => {
  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Извините что то пошло не так"
        extra={
          <Button type="link" href="/">
            Вернуться на главую страницу
          </Button>
        }
      />
    )
  }
  return children
})

export default MyErrorBoundary
