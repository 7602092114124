function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/'

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  logIn: path(ROOTS_AUTH, '/login'),
  signUp: path(ROOTS_AUTH, '/signup'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  verifyEmail: path(ROOTS_AUTH, '/verify'),
}

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
}

export const PATH_APP = {
  root: ROOTS_DASHBOARD,
  welcome: path(ROOTS_DASHBOARD, 'welcome'),
  cases: path(ROOTS_DASHBOARD, 'cases'),
  reports: path(ROOTS_DASHBOARD, 'reports'),
}
