import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import pkg from '../package.json'
import ErrorBoundary from './components/Errors/Boundary'
// import { ToastContainer } from 'components/Toast/components'
import '@style'
import App from 'app'
import * as Sentry from '@sentry/react'
import { history, persistor, store } from 'app/Store'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('Looks like we are in development mode!')
}

console.info(`Version of service: ${pkg.version}`)

Sentry.init({
  dsn: 'https://0a70d0377c28c17be8786c30923dff2f@o4508341787492352.ingest.de.sentry.io/4508341789196368',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/otts\.bot\/v1/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = createRoot(document.getElementById('root'))
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
)
