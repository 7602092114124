import { all, fork } from 'redux-saga/effects'
import authSaga from 'pages/Auth/redux/saga'
import graphTableSaga from '@components/Graphs/Table/saga'
import homeSaga from 'pages/Home/redux/saga'
import ottsSaga from 'pages/Otts/redux/saga'
import paymentSaga from 'pages/Payment/redux/saga'
import userSaga from 'pages/User/redux/saga'
import profileSaga from 'pages/Profile/redux/saga'

function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(graphTableSaga),
    fork(homeSaga),
    fork(ottsSaga),
    fork(paymentSaga),
    fork(profileSaga),
    fork(userSaga),
  ])
}

export default rootSaga
