import {
  TABLE_DATA_REQUEST,
  TABLE_DATA_SUCCESS,
  TABLE_META_PATCH,
  TABLE_META_SET,
} from './constants'

export const tableDataRequest = ({ meta }) => ({
  type: TABLE_DATA_REQUEST,
  meta,
})

export const tableDataSuccess = ({ payload, meta }) => ({
  type: TABLE_DATA_SUCCESS,
  payload,
  meta,
})

export const tableMetaSet = ({ payload, meta }) => ({
  type: TABLE_META_SET,
  payload,
  meta,
})

export const tableMetaPatch = ({ payload, meta }) => ({
  type: TABLE_META_PATCH,
  payload,
  meta,
})
