import { fromJS } from 'immutable'

import { GET_FILES_SUCCESS, GET_FILES_REQUEST } from './constants'

const initialState = fromJS({
  // error: null,
  // data: null,
  // loading: false,
  documents: null,
})

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FILES_REQUEST:
      return state
        .set('documents', null)
        .set('error', null)
        .set('loading', true)
    case GET_FILES_SUCCESS:
      const { data } = payload
      return state
        .set('documents', data)
        .set('error', null)
        .set('loading', false)
    default:
      return state
  }
}

export default profileReducer
