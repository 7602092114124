export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE'
export const CLEAR_PLANS = 'CLEAR_PLANS'

export const GET_AVALIABLE_PAYMENTS_METHODS_REQUEST =
  'GET_AVALIABLE_PAYMENTS_METHODS_REQUEST'
export const GET_AVALIABLE_PAYMENTS_METHODS_SUCCESS =
  'GET_AVALIABLE_PAYMENTS_METHODS_SUCCESS'
export const GET_AVALIABLE_PAYMENTS_METHODS_FAILURE =
  'GET_AVALIABLE_PAYMENTS_METHODS_FAILURE'

export const CREATE_PAYMENT_TRANSACTIONS_REQUEST =
  'CREATE_PAYMENT_TRANSACTIONS_REQUEST'
export const CREATE_PAYMENT_TRANSACTIONS_SUCCESS =
  'CREATE_PAYMENT_TRANSACTIONS_SUCCESS'
export const CREATE_PAYMENT_TRANSACTIONS_FAILURE =
  'CREATE_PAYMENT_TRANSACTIONS_FAILURE'

export const CLEAR_PAYMENTS_DATA = 'CLEAR_PAYMENTS_DATA'

export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST'
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE'
