import {
  VERIFY_LOGIN_TOKEN_FAILURE,
  VERIFY_LOGIN_TOKEN_SUCCESS,
  VERIFY_LOGIN_TOKEN_REQUEST,
  REFRESH_TOKEN_REQUEST,
  TEST_TOKEN_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  REQUEST_LOGIN_EMAIL_REQUEST,
} from './constants'

export const logInRequest = ({ token, navigate }) => ({
  type: VERIFY_LOGIN_TOKEN_REQUEST,
  payload: { token, navigate },
})

export const logInSuccess = (data) => ({
  type: VERIFY_LOGIN_TOKEN_SUCCESS,
  payload: data,
})

export const logInFailure = (error, form, customMessage) => ({
  type: VERIFY_LOGIN_TOKEN_FAILURE,
  customMessage,
  error,
  form,
})

export const logOutRequest = (payload) => ({
  type: LOGOUT_REQUEST,
  payload,
})

export const logOutSuccess = () => ({
  type: LOGOUT_SUCCESS,
  payload: '',
})

export const logOutFailure = (message) => ({
  type: LOGOUT_FAILURE,
  payload: message,
})

export const refreshToken = (payload) => ({
  type: REFRESH_TOKEN_REQUEST,
  payload,
})

export const refreshTokenSuccess = (payload) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload,
})

export const testToken = () => ({
  type: TEST_TOKEN_REQUEST,
})

export const sendRequestLoginEmail = (email) => ({
  type: REQUEST_LOGIN_EMAIL_REQUEST,
  payload: { email },
})
