export const VERIFY_LOGIN_TOKEN_REQUEST = 'VERIFY_LOGIN_TOKEN_REQUEST'
export const VERIFY_LOGIN_TOKEN_SUCCESS = 'VERIFY_LOGIN_TOKEN_SUCCESS'
export const VERIFY_LOGIN_TOKEN_FAILURE = 'VERIFY_LOGIN_TOKEN_FAILURE'

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE'

export const TEST_TOKEN_REQUEST = 'TEST_TOKEN_REQUEST'
export const TEST_TOKEN_SUCCESS = 'TEST_TOKEN_SUCCESS'
export const TEST_TOKEN_FAILURE = 'TEST_TOKEN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const REQUEST_LOGIN_EMAIL_REQUEST = 'REQUEST_LOGIN_EMAIL_REQUEST'
