import { fromJS } from 'immutable'

import { GET_USER_INFO_SUCCESS } from './constants'

const initialState = fromJS({
  error: null,
  data: null,
})

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USER_INFO_SUCCESS: {
      return state.set('data', payload).set('error', null)
    }

    default:
      return state
  }
}

export default userReducer
