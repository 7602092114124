import { fromJS } from 'immutable'

import {
  CLEAR_PLANS,
  GET_PLANS_FAILURE,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_AVALIABLE_PAYMENTS_METHODS_SUCCESS,
  CLEAR_PAYMENTS_DATA,
} from './constants'

const initialState = fromJS({
  error: null,
  data: null,
  loading: false,
  plans: null,
  methods: null,
})

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PLANS_REQUEST:
      return state.set('plans', null).set('error', null).set('loading', true)
    case GET_PLANS_SUCCESS:
      const { data } = payload
      return state.set('plans', data).set('error', null).set('loading', false)
    case GET_PLANS_FAILURE:
      const { error } = payload
      return state.set('plans', null).set('error', error).set('loading', false)
    case GET_AVALIABLE_PAYMENTS_METHODS_SUCCESS:
      const { data: methods } = payload
      return state
        .set('methods', methods)
        .set('error', null)
        .set('loading', false)
    case CLEAR_PLANS:
    case CLEAR_PAYMENTS_DATA:
      return initialState
    default:
      return state
  }
}

export default userReducer
