import createAction from 'utils/createAction'

import {
  GET_OTTS_FAILURE,
  GET_OTTS_REQUEST,
  GET_OTTS_SUCCESS,
  DOWNLOAD_FILE_OTTS_REQUEST,
  DOWNLOAD_FILE_OTTS_SUCCESS,
  DOWNLOAD_FILE_OTTS_FAILURE,
} from './constants'

export const getOttsRequest = createAction(GET_OTTS_REQUEST)
export const getOttsSuccess = createAction(GET_OTTS_SUCCESS)
export const getOttsFailure = createAction(GET_OTTS_FAILURE)

// Download
export const downloadFileOttsRequest = createAction(DOWNLOAD_FILE_OTTS_REQUEST)
export const downloadFileOttsSuccess = createAction(DOWNLOAD_FILE_OTTS_SUCCESS)
export const downloadFileOttsFailure = createAction(DOWNLOAD_FILE_OTTS_FAILURE)
