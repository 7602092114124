import { all, call, put, takeLatest } from 'redux-saga/effects'

import { GET_ALL_OTTSES_REQUEST } from './constants'
import OTTS_API from '../api'

const listOfKeys = [
  'fullTextSearch',
  'approvalNumber',
  'accreditationBy',
  'model',
  'tradeName',
  'chassis',
  'modifications',
  'category',
  'vin',
  'ecoClass',
  'applicantAndAddress',
  'manufacturerAndAddress',
  'assemblyPlantAddress',
]

function* getAllOtteses({
  meta: {
    table,
    callback,
    page: queryPage,
    size: querySize,
    sortBy,
    directionSort,
    search,
    searchBy,
    fullTextSearch,
    approvalNumber,
    accreditationBy,
    model,
    tradeName,
    chassis,
    modifications,
    category,
    vin,
    ecoClass,
    applicantAndAddress,
    manufacturerAndAddress,
    assemblyPlantAddress,
  },
}) {
  try {
    const { page, limit, totalResults, results } = yield call(
      OTTS_API.getAllOttses,
      {
        page: queryPage,
        limit: querySize,
        sortBy,
        directionSort,
        search,
        searchBy,
        fullTextSearch,
        approvalNumber,
        accreditationBy,
        model,
        tradeName,
        chassis,
        modifications,
        category,
        vin,
        ecoClass,
        applicantAndAddress,
        manufacturerAndAddress,
        assemblyPlantAddress,
      },
    )
    if (callback) {
      return yield put(
        callback({
          meta: {
            table,
            page,
            size: limit,
            sortBy,
            directionSort,
            search,
            searchBy,
            totalSize: totalResults,
            fullTextSearch,
            approvalNumber,
            accreditationBy,
            model,
            tradeName,
            chassis,
            modifications,
            category,
            vin,
            ecoClass,
            applicantAndAddress,
            manufacturerAndAddress,
            assemblyPlantAddress,
          },
          payload: { response: results },
        }),
      )
    }
  } catch (error) {
    // openNotification({
    //   type: 'error',
    //   message: 'The list of surveys could not be received.',
    // })
    console.error(error)
  }
}

function* Saga() {
  yield all([takeLatest(GET_ALL_OTTSES_REQUEST, getAllOtteses)])
}

export default Saga
