import request from 'api'

class ClassAPI {
  getPlans = () =>
    request({
      url: `v1/payment/plans`,
      method: 'GET',
    })

  getAvaliablePaymentsMethods = (amount) =>
    request({
      url: `v1/payment/available?amount=${amount}`,
      method: 'GET',
    })

  createPaymentTransactions = (data) =>
    request({
      url: `v1/payment/transaction`,
      method: 'POST',
      data,
    })

  subscribe = (data) =>
    request({
      url: `v1/payment/subscribe`,
      method: 'POST',
      data,
    })
}

const PAYMENT_API = new ClassAPI()

export default PAYMENT_API
