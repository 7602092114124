import axios from 'axios'
import { get, isEmpty } from 'lodash'
import API_GLOBAL from 'api/api'
import { logOutRequest } from '../pages/Auth/redux/action'

let repeatCount = 1
const maxRepeatCount = 3
const REST = process.env.REACT_APP_API_REST

const ConfigStore = require('app/Store')

const makeRequest = (options) =>
  axios(options)
    .then((response) => {
      if (![200, 201, 204].includes(response.status)) {
        throw response
      }
      return response.data
    })
    .catch((error) => {
      const codeR = get(error, ['response', 'status'])
      const code = get(error, ['status'])
      if ((code || codeR) === 502 && repeatCount < maxRepeatCount) {
        // eslint-disable-next-line no-plusplus
        repeatCount++
        return makeRequest(options)
      }

      if ([403, 401].includes(code || codeR)) {
        ConfigStore.store.dispatch(logOutRequest())
        return Promise.reject(error?.response?.data)
      }
      if ((code || codeR) === 299) {
        return Promise.reject(error?.data)
      }
      return Promise.reject(error?.response?.data)
    })
export default ({ method, url, data, params }) => {
  // eslint-disable-next-line no-param-reassign

  url = `${isEmpty(REST) ? window.location.origin : REST}/${url}`
  const options = { method, url }
  if (data) {
    options.data = data
  }

  if (params) {
    options.params = params
  }

  options.headers = {
    Authorization: API_GLOBAL.token,
  }

  // options.withCredentials = true
  return makeRequest(options)
}
