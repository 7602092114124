import request from 'api'

class ClassAPI {
  files = () =>
    request({
      url: 'v1/user/files',
      method: 'GET',
    })
}

const AUTH_API = new ClassAPI()
export default AUTH_API
