import React, { lazy } from 'react'
import loadable from '@components/Loadable'
import { useRoutes } from 'react-router-dom'
import { GuestGuard, AuthGuard } from 'guards'

const PublicLayout = loadable(lazy(() => import('../Layouts/Public')))
// const PrivateLayout = loadable(lazy(() => import('../Layouts/Private')))
const Home = loadable(lazy(() => import('pages/Home')))
const Contact = loadable(lazy(() => import('pages/Contact')))
const Document = loadable(lazy(() => import('pages/Otts')))
const Offer = loadable(lazy(() => import('pages/Offer')))
const Plans = loadable(lazy(() => import('pages/Payment/pages/plans')))
const Profile = loadable(lazy(() => import('pages/Profile')))
const Auth = loadable(lazy(() => import('pages/Auth')))
const Success = loadable(lazy(() => import('pages/Payment/pages/success')))
const Failure = loadable(lazy(() => import('pages/Payment/pages/failure')))
const NotFound = loadable(
  lazy(() => import('../../components/Errors/NotFound')),
)

const Router = () =>
  useRoutes([
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: 'contact',
          element: <Contact />,
        },
        {
          path: 'otts',
          children: [
            {
              index: true,
              element: <Home />,
            },
            {
              path: ':ottsId',
              element: <Document />,
            },
          ],
        },
        {
          path: 'payment',
          children: [
            {
              path: 'success',
              element: <Success />,
            },
            {
              path: 'failure',
              element: <Failure />,
            },
            {
              path: 'plans',
              element: <Plans />,
            },
          ],
        },
        {
          path: 'offer',
          element: <Offer />,
        },
        {
          path: 'profile',
          element: (
            <AuthGuard>
              <Profile />
            </AuthGuard>
          ),
        },
        {
          path: 'auth/verify',
          element: <Auth />,
        },
        { path: '*', element: <NotFound /> },
      ],
    },
  ])

export default Router
