class ClassAPI {
  token = ''

  middleware =
    ({ getState }) =>
    (next) =>
    (action) => {
      const state = getState()
      const token = state?.auth?.get('tokens')?.access?.token
      const refreshToken = state?.auth?.get('tokens')?.refresh?.token

      this.locale = state?.user?.get('locale') || 'en'
      this.token = token
      this.refreshToken = refreshToken

      return next(action)
    }
}

const API_GLOBAL = new ClassAPI()

export default API_GLOBAL
