import {
  GET_FILES_FAILURE,
  GET_FILES_SUCCESS,
  GET_FILES_REQUEST,
} from './constants'

export const getFilesRequest = () => ({
  type: GET_FILES_REQUEST,
})

export const getFilesSuccess = (data) => ({
  type: GET_FILES_SUCCESS,
  payload: data,
})

export const getFilesFailure = () => ({
  type: GET_FILES_FAILURE,
})
