import { all, call, put, takeLatest } from 'redux-saga/effects'

import { downloadFile } from '../utils/fileService'
import { getModal } from '../utils/modals'
import { openModal } from '../../../components/Modal/actions'
import { GET_OTTS_REQUEST, DOWNLOAD_FILE_OTTS_REQUEST } from './constants'
import OTTS_API from '../api'
import { getOttsSuccess, getOttsFailure } from './actions'
import { getUserInfoRequest } from '../../User/redux/action'

function* getOtts({ ottsId }) {
  try {
    const response = yield call(OTTS_API.getOtts, ottsId)
    return yield put(getOttsSuccess({ payload: { data: response } }))
  } catch (error) {
    return yield put(getOttsFailure({ payload: { error } }))
  }
}

function* downloadFileOtts({ ottsId }) {
  try {
    const { isAvailable, singedUrl, fileName } = yield call(
      OTTS_API.download,
      ottsId,
    )
    if (isAvailable) {
      yield call(downloadFile, singedUrl, fileName)
      return yield put(getUserInfoRequest())
    }
  } catch (error) {
    if (!error.isAvailable) {
      yield put(openModal({ id: getModal(error.reasonCode) }))
    }
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_OTTS_REQUEST, getOtts),
    takeLatest(DOWNLOAD_FILE_OTTS_REQUEST, downloadFileOtts),
  ])
}

export default Saga
