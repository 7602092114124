import { all, call, takeLatest, put } from 'redux-saga/effects'

import AUTH_API from '../api'
import { GET_USER_INFO_REQUEST } from './constants'
import { getUserInfoSuccess } from './action'

function* getUserInfo() {
  try {
    const data = yield call(AUTH_API.getUserInfo)
    yield put(getUserInfoSuccess(data))
  } catch (error) {
    console.error(error)
  }
}

function* Saga() {
  yield all([takeLatest(GET_USER_INFO_REQUEST, getUserInfo)])
}

export default Saga
