import { all, call, takeLatest, put } from 'redux-saga/effects'

import AUTH_API from '../api'
import { GET_FILES_REQUEST } from './constants'

import { getFilesSuccess } from './action'

function* getFiles() {
  try {
    const data = yield call(AUTH_API.files)
    yield put(getFilesSuccess({ data }))
  } catch (error) {
    console.error(error)
  }
}

function* Saga() {
  yield all([takeLatest(GET_FILES_REQUEST, getFiles)])
}

export default Saga
