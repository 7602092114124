import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
} from './constants'

export const getUserInfoRequest = () => ({
  type: GET_USER_INFO_REQUEST,
})

export const getUserInfoSuccess = (data) => ({
  type: GET_USER_INFO_SUCCESS,
  payload: data,
})

export const getUserInfoFailure = (error) => ({
  type: GET_USER_INFO_FAILURE,
  payload: error,
})
