import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  GET_PLANS_REQUEST,
  GET_AVALIABLE_PAYMENTS_METHODS_REQUEST,
  CREATE_PAYMENT_TRANSACTIONS_REQUEST,
  SUBSCRIBE_REQUEST,
} from './constants'
import PAYMENT_API from '../api'
import { openModal } from '../../../components/Modal/actions'
import { getModal } from '../../Otts/utils/modals'
import { getPlansSuccess, getAvaliablePaymentsMethodsSuccess } from './actions'
import { getUserInfoRequest } from '../../User/redux/action'

function* getPlans() {
  try {
    const response = yield call(PAYMENT_API.getPlans)
    return yield put(getPlansSuccess({ payload: { data: response } }))
  } catch (error) {
    console.error(error)
  }
}

function* getAvaliablePaymentsMethods({ amount }) {
  try {
    const response = yield call(PAYMENT_API.getAvaliablePaymentsMethods, amount)
    return yield put(
      getAvaliablePaymentsMethodsSuccess({ payload: { data: response } }),
    )
  } catch (error) {
    console.error(error)
  }
}

function* createPaymentTransactions(payload) {
  try {
    const response = yield call(PAYMENT_API.createPaymentTransactions, payload)
    window.location.href = response.url
  } catch (error) {
    console.error(error)
  }
}

function* subscribe(payload) {
  try {
    const response = yield call(PAYMENT_API.subscribe, payload)
    yield put(getUserInfoRequest())
    return yield put(subscribeSuccess({ payload: { data: response } }))
  } catch (error) {
    if (!error.isAvailable) {
      yield put(openModal({ id: getModal(error.reasonCode) }))
    }
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_PLANS_REQUEST, getPlans),
    takeLatest(
      GET_AVALIABLE_PAYMENTS_METHODS_REQUEST,
      getAvaliablePaymentsMethods,
    ),
    takeLatest(CREATE_PAYMENT_TRANSACTIONS_REQUEST, createPaymentTransactions),
    takeLatest(SUBSCRIBE_REQUEST, subscribe),
  ])
}

export default Saga
