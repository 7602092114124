import { fromJS } from 'immutable'

import {
  VERIFY_LOGIN_TOKEN_SUCCESS,
  TEST_TOKEN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REFRESH_TOKEN_SUCCESS,
} from './constants'

const initialState = fromJS({
  error: null,
  tokens: null,
  user: null,
})

// eslint-disable-next-line default-param-last
const logInReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case VERIFY_LOGIN_TOKEN_SUCCESS: {
      const { tokens, user } = payload
      return state.set('tokens', tokens).set('user', user).set('error', null)
    }

    case TEST_TOKEN_FAILURE:
    case LOGOUT_SUCCESS: {
      return state.set('tokens', null).set('error', null).set('user', null)
    }

    case REFRESH_TOKEN_SUCCESS: {
      return state.update('tokens', () => ({ ...payload })).set('error', null)
    }

    default:
      return state
  }
}

export default logInReducer
