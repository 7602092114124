import { fromJS } from 'immutable'

import { CLOSE_MODAL, OPEN_MODAL, UPDATE_PROPS_MODAL } from './constants'

const initialState = fromJS({})

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, id, props }) => {
  switch (type) {
    case OPEN_MODAL: {
      return state.setIn([id, 'open'], true).setIn([id, 'props'], props)
    }

    case CLOSE_MODAL: {
      return state.setIn([id, 'open'], false)
    }

    case UPDATE_PROPS_MODAL: {
      return state.updateIn([id, 'props'], (data) => ({ ...data, ...props }))
    }

    default:
      return state
  }
}

export default reducer
