import React, { Suspense } from 'react'

const Loadable = (Component) =>
  function (props) {
    return (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    )
  }

export default Loadable
