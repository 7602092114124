import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { PATH_AUTH } from 'app/Router/paths'
import { createStructuredSelector } from 'reselect'
import { makeSelectToken } from 'app/selectors'
import { connect } from 'react-redux'
import { compose } from 'redux'

function AuthGuard({ children, token }) {
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null)

  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Navigate to="/" />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return children
}

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(AuthGuard)
