import React from 'react'
import { Navigate } from 'react-router-dom'

import { PATH_APP } from 'app/Router/paths'
import { createStructuredSelector } from 'reselect'
import { makeSelectToken } from 'app/selectors'
import { connect } from 'react-redux'
import { compose } from 'redux'

function GuestGuard({ token, children }) {
  if (token) {
    return <Navigate to={PATH_APP.root} />
  }

  return children
}

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(GuestGuard)
