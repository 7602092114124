// {
//     "isAvailable": false,
//     "reason": "Balance is not enough",
//     "reasonCode": "BALANCE_NOT_ENOUGH"
// }

// Premium
export const BALANCE_NOT_ENOUGH = {
  reasonCode: 'BALANCE_NOT_ENOUGH',
  title: 'Недостаточно средств на балансе для скачивания, пополнить баланс?',
  action: {
    title: 'Пополнить баланс',
  },
}

export const BALANCE_NOT_ENOUGH_FOR_SUBSCRIPTION = {
  reasonCode: 'BALANCE_NOT_ENOUGH_FOR_SUBSCRIPTION',
  title: 'Недостаточно средств на балансе для подписки, пополнить баланс?',
  action: {
    title: 'Пополнить баланс',
  },
}

export const USER_HAS_NO_SUBSCRIPTION = {
  reasonCode: 'USER_HAS_NO_SUBSCRIPTION',
  title:
    'У вас нет активной подписки для скачивания файла, приобрести подписку?',
  action: {
    title: 'Приобрести подписку',
  },
}

export const YOU_SPEND_ALL_LIMIT_ON_YOUR_PLAN_FILE_IS_NOT_AVAILABLE_BUT_YOU_CAN_ADD_MONEY_TO_YOUR_BALANCE_FOR_UPDATE_PLAN =
  {
    reasonCode:
      'YOU_SPEND_ALL_LIMIT_ON_YOUR_PLAN_FILE_IS_NOT_AVAILABLE_BUT_YOU_CAN_ADD_MONEY_TO_YOUR_BALANCE_FOR_UPDATE_PLAN',
    title:
      'Вы потратили все лимиты скачивания для тукущей подписки, у вас не достаточно денег для перехода на расширенный план, пополнить баланс?',
    action: {
      title: 'Пополнить баланс',
    },
  }

export const BALANCE_IS_ENOUGH_FOR_NEXT_PLAN = {
  reasonCode: 'BALANCE_IS_ENOUGH_FOR_NEXT_PLAN',
  title:
    'Вы потратили все лимиты скачивания для тукущей подписки, у вас достаточно денег для перехода на расширенный план, перейти на расширенный план?',
  action: {
    title: 'Расширить план',
  },
}

export const DEFAULT_MODAL = {
  reasonCode: 'DEFAULT_MODAL',
  title: 'Ошибка при скачивании файла',
  actions: [],
}

// {
//     "isAvailable": false,
//     "reason": "You spend all limit on your plan. File is not available, but you can add money to your balance for update plan",
//     "reasonCode": "YOU_SPEND_ALL_LIMIT_ON_YOUR_PLAN_FILE_IS_NOT_AVAILABLE_BUT_YOU_CAN_ADD_MONEY_TO_YOUR_BALANCE_FOR_UPDATE_PLAN"
// }

// {
//     "isAvailable": false,
//     "reason": "Balance is enough for next plan",
//     "reasonCode": "BALANCE_IS_ENOUGH_FOR_NEXT_PLAN"
// }

export const getModal = (reasonCode) => {
  switch (reasonCode) {
    case 'BALANCE_NOT_ENOUGH':
      return BALANCE_NOT_ENOUGH.reasonCode
    case 'USER_HAS_NO_SUBSCRIPTION':
      return USER_HAS_NO_SUBSCRIPTION.reasonCode
    case 'YOU_SPEND_ALL_LIMIT_ON_YOUR_PLAN_FILE_IS_NOT_AVAILABLE_BUT_YOU_CAN_ADD_MONEY_TO_YOUR_BALANCE_FOR_UPDATE_PLAN':
      return YOU_SPEND_ALL_LIMIT_ON_YOUR_PLAN_FILE_IS_NOT_AVAILABLE_BUT_YOU_CAN_ADD_MONEY_TO_YOUR_BALANCE_FOR_UPDATE_PLAN.reasonCode
    case 'BALANCE_IS_ENOUGH_FOR_NEXT_PLAN':
      return BALANCE_IS_ENOUGH_FOR_NEXT_PLAN.reasonCode
    default:
      return DEFAULT_MODAL.reasonCode
  }
}
