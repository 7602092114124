import { all, call, takeLatest, put } from 'redux-saga/effects'

import AUTH_API from '../api'
import {
  VERIFY_LOGIN_TOKEN_REQUEST,
  TEST_TOKEN_REQUEST,
  REFRESH_TOKEN_REQUEST,
  LOGOUT_REQUEST,
  REQUEST_LOGIN_EMAIL_REQUEST,
  LOGOUT_SUCCESS,
} from './constants'
import { resetStore } from '@components/StateManagement/action'
import {
  logInSuccess,
  logOutSuccess,
  logOutRequest,
  refreshTokenSuccess,
} from './action'

function* logIn({ payload: { token, navigate } }) {
  try {
    const { tokens, user } = yield call(AUTH_API.login, { token })
    yield put(logInSuccess({ tokens, user }))
    navigate('/')
  } catch (error) {
    console.error(error)
  }
}

function* refreshToken({ payload: { navigate } }) {
  try {
    const tokens = yield call(AUTH_API.refreshToken)
    yield put(refreshTokenSuccess(tokens))
  } catch (error) {
    console.error(error)
    yield put(logOutRequest({ navigate }))
  }
}

function* testToken() {
  try {
    yield call(AUTH_API.testToken)
  } catch (error) {
    console.error(error)
  }
}

function* logOut({ payload }) {
  try {
    yield put(logOutSuccess())
    yield put(resetStore())
  } catch (error) {
    console.error(error)
  }
}

function* loginRequestEmail({ payload }) {
  try {
    yield call(AUTH_API.loginRequestEmail, {
      email: payload?.email,
    })
  } catch (error) {
    console.error(error)
  }
}

function* Saga() {
  yield all([
    takeLatest(VERIFY_LOGIN_TOKEN_REQUEST, logIn),
    takeLatest(TEST_TOKEN_REQUEST, testToken),
    // takeLatest(REFRESH_TOKEN_REQUEST, refreshToken),
    takeLatest(LOGOUT_REQUEST, logOut),
    takeLatest(REQUEST_LOGIN_EMAIL_REQUEST, loginRequestEmail),
  ])
}

export default Saga
