import { Component, ErrorInfo } from 'react'

export default function Catch(component, errorHandler) {
  return class extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      error: undefined,
    }

    static getDerivedStateFromError(error) {
      return { error }
    }

    componentDidCatch(error, info) {
      if (errorHandler) {
        errorHandler(error, info)
      }
    }

    render() {
      return component(this.props, this.state.error)
    }
  }
}
