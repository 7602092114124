import request from 'api'

class ClassAPI {
  getAllOttses = (params) =>
    request({
      url: 'v1/otts/list',
      method: 'GET',
      params,
    })
}

const OTTS_API = new ClassAPI()

export default OTTS_API
